import { Injectable, Output } from '@angular/core';
import Graphic from '@arcgis/core/Graphic';

import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

import * as filesaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CSVExportService {

  constructor() { }

  public exportFeaturesFromFeatureLayer(features: Array<Graphic>, layer: FeatureLayer, filename: string) {
    var outputBuffer = "";

    //Add the heading row to our output buffer.

    var columnHeadings = Array<string>();

    layer.fields.forEach((field) => {
      columnHeadings.push(field.name);
    });

    
    outputBuffer = columnHeadings.join(",") + "\r\n";


    //Do the data rows
    for (var i = 0; i < features.length; i++) {
      var feature = features[i];

      var vals = Array<string>();

      layer.fields.forEach((field) => {

        vals.push(feature.attributes[field.name]);
      });

      var featureLineBuffer = vals.join(",");

      outputBuffer = outputBuffer + featureLineBuffer + "\r\n";
    }
    

    //Finally, actually do the saving.
    let blob = new Blob([outputBuffer], {
      type: "text/csv"
    })

    filesaver.saveAs(blob, filename);
  }
}
