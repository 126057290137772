import { Component, ViewChild, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AssetsService } from './../_services/assets.service';
import { WelcomeDialogComponent } from './../welcome-dialog/welcome-dialog.component';
import { CSVExportService } from '../_services/csvexport.service';

//Load up ESRI classes
import WebMap from '@arcgis/core/WebMap';
import MapView from '@arcgis/core/views/MapView';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import LayerList from '@arcgis/core/widgets/LayerList';
import TimeSlider from '@arcgis/core/widgets/TimeSlider';
import Popup from '@arcgis/core/widgets/Popup';
import DistanceMeasurement2D from '@arcgis/core/widgets/DistanceMeasurement2D';
import TimeExtent from '@arcgis/core/TimeExtent';
import Graphic from '@arcgis/core/Graphic';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  protected _basemap: string = "topo";

  protected _center = [-97.5, 31.4];

  protected _zoom: number = 7;

  protected _fullTimeExtent: TimeExtent = new TimeExtent({
    start: new Date(2017, 0, 1, 0, 0, 0, 0),
    end: new Date(2024, 10, 31, 23, 59, 59)
  });


  protected _timeExtent: TimeExtent = new TimeExtent({
    start: new Date(2017, 0, 1, 0, 0, 0, 0),
    end: new Date(2024, 10, 31, 23, 59, 59)
  });

  _map: WebMap;
  _mapView: MapView;
  _earthquakesLayer: FeatureLayer;
  _layerList: LayerList = null;
  _timeSlider: TimeSlider;
  _measurementWidget: DistanceMeasurement2D;

  _activeMeasureWidget: any = null;

  _filterGraphic: Graphic = null;

  promptText: String = null;

  _earthquakeCount: number = 0;

  @ViewChild('mapViewNode', {}) protected mapViewEl: ElementRef;

  public get fullBeginTime(): Date {

    return this._fullTimeExtent.start;
  }

  public set fullBeginTime(newVal: Date) {
    this._fullTimeExtent.start = newVal;
  }

  public get fullEndTime(): Date {
    return this._fullTimeExtent.end;
  }

  public set fullEndTime(newVal: Date) {
    this._fullTimeExtent.end;
  }

  public get beginTime(): Date {
    return this._timeExtent.start;
  }

  public set beginTime(newVal: Date) {
    this._timeExtent.start = newVal;
  }

  public get endTime(): Date {
    return this._timeExtent.end;
  }

  public set endTime(newVal: Date) {
    this._timeExtent.end = newVal;
  }

  public get earthquakeCount(): number {
    return this._earthquakeCount;
  }

  public set earthquakeCount(newVal: number) {
    this._earthquakeCount = newVal;
  }

  public get earthquakesLayer(): FeatureLayer {
    return this._earthquakesLayer;
  }

  public set earthquakesLayer(newVal: FeatureLayer) {
    this._earthquakesLayer = newVal;

    this._earthquakesLayer.when((args) => {
      this.fetchEarthquakeCount();
      
    });
  }

  constructor(
    public assetsService: AssetsService,
    public csvExportService: CSVExportService,
    public matDialog: MatDialog
    //public exportService: ExportManagerService,
    //public browseMapImageServiceLayerService: BrowseMapImageServiceLayerService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    // Set type for Map constructor properties
    const mapProperties = {
      basemap: this._basemap
    };

    this._map = new WebMap(mapProperties);

    // Set type for MapView constructor properties
    const mapViewProperties = {
      container: this.mapViewEl.nativeElement,
      center: this._center,
      zoom: this._zoom,
      map: this._map
    };

    this._mapView = new MapView(mapViewProperties);

    //Create the layer list
    this._layerList = new LayerList({
      view: this._mapView,
      listItemCreatedFunction: function (event) {

        //This section forces the layer list to behave like a legend, with the swatches and so forth...
        const item = event.item;

        if (item.layer.type != undefined && item.layer.type != "group" && item.layer.type != "graphics") {
          item.panel = {
            content: "legend",
            open: true
          }
        }
      },
      container: "legend-container",
      visibilityAppearance: "checkbox"
    });

    this._mapView.when((view) => {

      this._mapView.popup = new Popup();

      //Set up action button handling on the popup
      this._mapView.popup.on("trigger-action", (event) => {
        var feature = this._mapView.popup.selectedFeature;

        if (event.action.id == "well-details") {
          //Launch the well details.
          var graphic = this._mapView.popup.selectedFeature;
          //this.launchWellDetails(graphic);
        }

        if (event.action.id == "export-data") {
          var graphic = this._mapView.popup.selectedFeature;
          //this.exportDataForWell(graphic);
        }
      });
    })

    //Create the AOI Sketch, for later.
    //this.initAOIDrawing();

    //Initialize the time slider
    this.initTimeSlider();

    //Initialize the measurement widget
    this.initMeasurement();

    this.initBusinessLayers();

    //Finally, set up our image layer, which is the data source
    //this.initMapImageLayer();

    //Launch the welcome dialog
    this.launchWelcomeDialog();
  }

  protected initTimeSlider() {
    var stopsByInterval: any = {
      interval: {
        value: 1,
        unit: "days"
      }
    };

    //Set up the time slider
    this._timeSlider = new TimeSlider({
      container: "timeSlider",
      view: this._mapView,
      fullTimeExtent: this._fullTimeExtent,
      timeExtent: this._timeExtent,
      stops: stopsByInterval
    });

    var _this = this;

    this._timeSlider.watch("timeExtent", function () {

      var teSlider = _this._timeSlider.timeExtent;

      if (teSlider != null) {
        _this.beginTime = teSlider.start;
        _this.endTime = teSlider.end;

        //Reload the AOI data
        _this.fetchEarthquakeCount();
      }
    });

    this._timeSlider.when((args) => {
      this.fetchEarthquakeCount();
    });
  }

  protected initMeasurement() {
    

    this._mapView.ui.add("measure-widget-container", "top-right");
  }

  public activateMeasureWidget(type: string) {

    if (this._activeMeasureWidget != null) {
      this.clearActiveMeasureWidget();
    }
    else {
      switch (type) {
        case "distance": this.activateMeasureDistanceWidget();
          break;
      }
    }
  }

  protected activateMeasureDistanceWidget() {
    this._activeMeasureWidget = new DistanceMeasurement2D({
      view: this._mapView
    });

    this._activeMeasureWidget.viewModel.start();

    this._mapView.ui.add(this._activeMeasureWidget, "top-right");
  }

  protected clearActiveMeasureWidget() {
    if (this._activeMeasureWidget != null) {
      this._mapView.ui.remove(this._activeMeasureWidget);
      this._activeMeasureWidget.destroy();
      this._activeMeasureWidget = null;
    }
  }

  protected initBusinessLayers() {
    this.assetsService.getAssetAsJson("assets/layers.json").then((layerConfigData: any) => {
      if (layerConfigData.layers != null) {
        layerConfigData.layers.forEach(layerconfig => {

          if (layerconfig.layerType == "feature") {
            this.createFeatureLayer(layerconfig);
          }
          else if (layerconfig.layerType == "mapimage") {
            this.createMapImageLayer(layerconfig);
          }
        });
      }
    },
    (err) => {
      var q = 0;
    })
  }

  protected launchWelcomeDialog() {
    //Do the welcome dialog...
    let dialogRef = this.matDialog.open(WelcomeDialogComponent, {
      disableClose: true,
      panelClass: "stations-dialog-container",
    });
  }

  protected createFeatureLayer(layerconfig: any) {
    var fl = new FeatureLayer(layerconfig.layerProperties);

    //Do anything else we need to do to the layer?

    this._map.add(fl);

    //Do we have a popup template file path?
    if (layerconfig.popupTemplatePath != null) {
      this.setupFeatureLayerPopupTemplate(layerconfig.popupTemplatePath, fl);
    }

    if (layerconfig.title != null) {
      fl.title = layerconfig.title;
    }

    if (layerconfig.isEarthquakesLayer != null && layerconfig.isEarthquakesLayer == true) {
      this.earthquakesLayer = fl;


    }
  }

  protected createMapImageLayer(layerconfig: any) {

  }

  protected setupFeatureLayerPopupTemplate(popupTemplatePath: string, layer: FeatureLayer) {
    this.assetsService.getAssetAsJson(popupTemplatePath).then((popupTemplateData: any) => {
      try {
        layer.popupEnabled = true;
        layer.popupTemplate = popupTemplateData;
      }
      catch (exc) {
        var q = 0;
      }
    },
    err => {

    });
  }

  public exportEarthquakeData() {

    if (this._earthquakesLayer != null) {

      var layerWrapper = {
        layer: this._earthquakesLayer
      };

      this.fetchAllEarthquakesForLayerWithWhereClause("1=1", this.beginTime, this.endTime, this._filterGraphic, layerWrapper, false).then(
        (result) => {

          var sBeginDate = this.beginTime.toJSON();
          var sEndDate = this.endTime.toJSON();

          var filename = "texnet_hirescatalog_" + sBeginDate + "_" + sEndDate + ".csv";

          this.csvExportService.exportFeaturesFromFeatureLayer(result, this._earthquakesLayer, filename);
        },
        (err) => {
          var q = 0;
        }
      )
    }

  }

  public fetchAllEarthquakesForLayerWithWhereClause(whereClause, beginDate, endDate, filterGraphic, layerWrapper, countOnly: boolean): Promise<any> {

    let p = new Promise((resolve, reject) => {

      var resultFeatures = [];
      var resultCount = 0;

      var layer = layerWrapper.layer;

      if (layer.visible) {
        var offset = 0;
        var strideSize = 1000;

        var timeExtent = {
          start: beginDate,
          end: endDate
        };

        var finalWhereClause = layer.definitionExpression;

        if (whereClause != null && whereClause != "") finalWhereClause = whereClause;

        function doFeatureQuery(offset) {

          var prom = new Promise(function (resolve1, reject1) {
            var fQuery = layer.createQuery();

            fQuery.where = finalWhereClause;
            fQuery.outFields = ["*"];
            fQuery.returnGeometry = true;
            fQuery.timeExtent = timeExtent;

            //Apparently, this applies even when requesting just the count, so we only add it when 
            if (countOnly) {
              //Kludgey, but by the time we've had 10M earthquakes, this will be some other poor schlub's problem
              fQuery.num = 10000000;
            }
            else {
              fQuery.num = strideSize;
            }

            if (filterGraphic != null) {
              fQuery.geometry = filterGraphic.geometry;
            }

            fQuery.start = offset;

            if (countOnly) {

              //If what we actually have is a sublayer, create a feature layer instance so we can get access to the feature count method
              if (layer.queryFeatureCount == null) {
                layer.createFeatureLayer().then((fLayerCreateResult) => {
                  //Now that we have a feature layer, we can try to query the feature count.
                  fLayerCreateResult.queryFeatureCount(fQuery).then(function (result) {
                    resolve1(result);
                  },
                    function (err) {
                      reject1(err);
                    });
                },
                  (err) => {
                    reject1(err);
                  });
              }
              else {
                layer.queryFeatureCount(fQuery).then(function (result) {
                  resolve1(result);
                },
                  function (err) {
                    reject1(err);
                  });
              }


            }
            else { //Fetching the actual features
              layer.queryFeatures(fQuery).then(function (result) {
                resolve1(result);
              },
                function (err) {
                  reject1(err);
                });
            }
          });

          return prom;
        }

        function handleQueryResult(result) {

          if (countOnly) {
            resultCount += result;

            resolve(resultCount);
          }
          else {

            result.features.forEach(function (feature) {
              resultFeatures.push(feature);
            })

            if (result.exceededTransferLimit) {
              offset += strideSize;

              doFeatureQuery(offset).then(handleQueryResult,
                function (err) {
                  reject();
                })
            }
            else {
              resolve(resultFeatures);
            }
          }
        }

        doFeatureQuery(offset).then(handleQueryResult,
          function (err) {
            reject();
          });
      }
    });

    return p;
  }

  protected fetchEarthquakeCount() {
    if (this._earthquakesLayer != null) {
      var layerWrapper = {
        layer: this._earthquakesLayer
      };

      this.fetchAllEarthquakesForLayerWithWhereClause("1=1", this.beginTime, this.endTime, this._filterGraphic, layerWrapper, true).then((earthquakeCount) => {
        this.earthquakeCount = earthquakeCount;
      },
      (err) => {

      });
    }
  }
}
