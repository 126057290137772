import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {



  constructor(public http: HttpClient) {
  }

  public getAssetAsJson(assetPath: string): Promise<any> {
    var prom = new Promise((resolve, reject) => {

      //Request the asset from the server.
      this.http.get(assetPath).subscribe((result: any) => {
        resolve(result);
      });
    });

    return prom;
  }
}
