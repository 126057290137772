import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.css']
})
export class WelcomeDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WelcomeDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  public dismissDialog() {

    var args = {

    };

    this.dialogRef.close(args);

  }

}
